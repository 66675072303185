












































































































import { Component, Vue } from 'vue-property-decorator';
import conf from '@/assets/config';

@Component({
  components: {
  },
})
export default class LandingPage extends Vue {
  signInPage = `${conf.app}/#/login`;
  registerPage = `${conf.app}/#/register`;
  samples = [
    {
      img: '/img/assets/screenshots/screen_archive.png',
      title: 'Store',
      text: 'Simply upload videos and we\'ll sort, archive, and prepare them for streaming.',
    },
    {
      img: '/img/assets/screenshots/screen_player.png',
      title: 'Play',
      text: 'Watch your favorite, curated memories back to back.',
    },
    {
      img: '/img/assets/screenshots/screen_invite.png',
      title: 'Share',
      text: 'Easily grant access to high definition video to grandparents & friends.'
    }
  ];
}
