import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  customVariables: ['../sass/variables.scss'],
  treeShake: true,
  theme: {
    dark: false,
    disable: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        background: '#E0E7FF',
        primary: '#010030',
        secondary: '#78C1EB',
        accent: '#BF2026',
        error: '#BF2026',
        warning: '#BF2026',
        info: '#78C1EB',
        success: '#78C1EB',
        yellow: '#FFC80A',
      },
      dark: {
        background: '#333333',
        primary: '#010030',
        secondary: '#78C1EB',
        accent: '#BF2026',
        error: '#BF2026',
        warning: '#BF2026',
        info: '#78C1EB',
        success: '#78C1EB',
        yellow: '#FFC80A',
      },
    },
  },
});
