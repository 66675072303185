









































import { defineComponent } from '@vue/composition-api'
import conf from '@/assets/config';

/* eslint-disable max-len */
const questions = [
  {
    title: 'What is Playbak Video?',
    answer: 'Playbak is a cloud storage & sharing app that lets you upload your favorite videos, sort them chronologically, share them securely with others, and play them back at any time on any of your devices.',
  },
  {
    title: 'What features does it have?',
    answer: `
      Playbak provides storage for your videos and converts them to modern, adaptive formats.
      We'll organize your videos automatically by date and let you play them back to back.
      The Free Plan offers up to 720p resolution and 2GB of storage.
    `,
  },
  {
    title: 'How much does it cost?',
    answer: `
      Right now we only have the Free Plan, with up to 720p (HD) video streaming as well as 2 GB of storage.  In the future we'll have paid plans that will offer higher resolutions and much higher storage limits.
    `,
  },
  {
    title: 'Where is my video stored?',
    answer: `Your video is stored securely and encrypted in Amazon S3, which offers a high degree of reliability and access.`,
  },
  {
    title: 'Who can see my videos?',
    answer: 'We take privacy very seriously. We limit access to your videos to only those you share them with. You can share individual streams with family and friends.',
  },
  {
    title: 'What is a stream?',
    answer: 'A stream is what we call a collection of videos. You can upload many videos to your stream and our player will play them back to back for you automatically so you can relive your favorite memories. You can also create multiple streams (2 with the Free Plan) to customize what you share with whom.',
  },
  {
    title: 'Who are you?',
    answer: `My name is Jason and I am the founder of Playbak Video. I have experience building out streaming services for large companies and realized when my wife and I couldn't share our videos easily, we needed a solution that let us do just that. Playbak is just getting started, but we'd love your help and feedback to make this product the best we can.`
  },
];
/* eslint-enable */

export default defineComponent({
  setup() {
    return null;
  },
  data() {
    return {
      questions,
      signInPage: `${conf.app}/#/login`,
      registerPage: `${conf.app}/#/register`,
    };
  }
})
